import React from 'react'
import { UserContext } from './AuthProvider'

export default function useUser() {
  const ctx  = React.useContext(UserContext)

  if (ctx) return {user: ctx.user, setUser: ctx.setUser}

  return {user: null}
}
