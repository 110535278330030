// Import the functions you need from the SDKs you need
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBMouWsEK4llbyAgmbcH8AsJV_YSZeU4NE",
  authDomain: "shushi-palace.firebaseapp.com",
  databaseURL: "https://shushi-palace-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "shushi-palace",
  storageBucket: "shushi-palace.appspot.com",
  messagingSenderId: "421642504928",
  appId: "1:421642504928:web:dfbad8161543ddfaba9e85"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const FireAuth = getAuth();
export const DataBase = getFirestore(app);

export default app;