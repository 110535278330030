import React, { useState } from "react";
import { Modal, Button, Box } from "@mui/material";
import axios from "axios";
import { FireAuth } from "../../../base";
import Notify from "../../../services/Notify";

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ open, onClose }) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("csv", file);
      const token = await FireAuth.currentUser?.getIdToken();
      formData.append("token", token || '');

      axios.post("https://admin.shushi-palace.am/apartments/csv-parser.php", formData)
        .then((response) => {
          // Handle the response
          Notify.success();
          
          setTimeout(() => window.location.reload(), 1000)
        })
        .catch((error) => {
          // Handle the error
          Notify.error();
          console.error("Error uploading file:", error);
        });
    }
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    text: "#fff",
    borderRadius: "20px",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <h3>Ներմուծեք վաճառքների համար նախատեսված հատուկ CSV ֆայլը</h3>
        <p>Ուշադրություն բոլոր ներկա վաճառքները <strong>Ջնջվելու են!</strong></p>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <hr className="mt-3" style={{margin: '20px 0'}}/>
        <Button variant="contained" color="primary" onClick={handleUpload} sx={{marginRight: '15px'}}>
          Upload
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default UploadModal;
