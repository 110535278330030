// import { DataBase } from "../base";
// import allsales from "../sales.json";
// import { collection, doc, writeBatch } from "firebase/firestore";
import { SaleItem } from "../models/models";


// export const synchData = () => {
//   const batch = writeBatch(DataBase);
//   allsales.forEach((sale, index) => {
//     let item = doc(collection(DataBase, "sales"));
//     batch.set(item, sale);
//   });

//   batch.commit();
// };

export const isAlreadySaleExist = (sales: SaleItem[], newSale: SaleItem) => {
  return sales.some((item) => isEqual(item, newSale));
};

function isEqual(sale1: SaleItem, sale2: SaleItem) {
  return !!(
    sale1.building?.toString().trim() == sale2.building?.toString().trim() &&
    sale1.floor?.toString().trim() == sale2.floor?.toString().trim() &&
    sale1.number?.toString().trim() == sale2.number?.toString().trim()
  );
}
