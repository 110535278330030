import React, {useState } from "react";
import { DataGrid, GridEventListener } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { SaleItem } from "../../../../models/models";
import columns from "./_columns";
import useSales from "./useSales";
import EditModal from "../EditModal";
import AddModal from "../AddModal";
import useModalValues from "../../../../hooks/useModalValues";

export default function Table() {
  const { sales, setSales } = useSales();
  const [entry, setEntry] = useState<SaleItem | null>(null);

  const [isEditModalOpen, opedEditModal, closeEditModal] = useModalValues();
  const [isAddModalOpen, opedAddModal, closeAddModal] = useModalValues();

  const onRowClickHandler: GridEventListener<"rowClick"> = (params, ev) => {
    setEntry(params.row);
    opedEditModal();
  };

  return (
    <div
      style={{
        height: 400,
        width: "100%",
        margin: "2rem auto",
        position: "relative",
      }}
    >
      <Button
        style={{ position: "absolute", right: 20, top: 10, zIndex: 10 }}
        onClick={opedAddModal}
        variant="contained"
      >
        Ավելացնել Նոր Վաճառք
      </Button>

      <DataGrid
        rows={sales}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[100]}
        onRowClick={onRowClickHandler}
      />

      {isEditModalOpen && (
        <EditModal
          item={entry}
          closeModal={closeEditModal}
          open={isEditModalOpen}
          setSales={setSales}
        />
      )}

      {isAddModalOpen && (
        <AddModal
          sales={sales}
          closeModal={closeAddModal}
          open={isAddModalOpen}
          setSales={setSales}
        />
      )}
    </div>
  );
}
