import { GridColDef } from "@mui/x-data-grid";

const columns: GridColDef[] = [
  { field: 'building', headerName: 'Մասնաշենք', width: 200 },
  { field: 'floor', headerName: 'Հարկ', width: 200 },
  { field: 'number', headerName: 'Բնկ․ համար', width: 200 },
  { field: 'index', headerName: 'հերթական համարը', width: 200 },
  { field: 'status', headerName: 'Կարգավիճակ', width: 200 },
];

export default columns