import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";

import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login";
import { ToastContainer } from 'react-toastify';
import fireApp from './base';

function App() {
  const darkTheme = createTheme({});

  return (
    <ThemeProvider theme={darkTheme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route path="/" element={<Dashboard />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
