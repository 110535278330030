import React, { useState } from 'react'

export default function useModalValues(): [boolean, () => void, () => void] {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false)
  const openModal = () => setIsOpen(true);

  return [isOpen, openModal, closeModal]
}
