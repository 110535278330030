import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { SaleItem, SaleItemCreation } from "../models/models";
import { FireAuth } from "../base";
import Notify from "./Notify";

let allSales: SaleItem[] = [];

// Fetch
async function fetchAll() {
  try {
    const resp = await axios.get(
      "https://admin.shushi-palace.am/apartments/sales.php"
    );

    allSales = resp.data;
    return allSales;
  } catch (e) {
    console.error(e);
    return [];
  }
}

// Create
async function create(s: SaleItemCreation): Promise<string> {
  const id = uuidv4();
  const sale: SaleItem = { ...s, id };
  allSales = [...allSales, sale];
  await __save();

  return id;
}

// Update
async function update(object: SaleItem): Promise<string> {
  allSales = allSales.map((obj) => {
    if (obj.id === object.id) return object;
    return obj;
  });

  await __save();

  return object.id;
}

// Delete
async function remove(id: string): Promise<string> {
  allSales = allSales.filter((obj) => obj.id !== id);

  await __save();

  return id;
}

// Save
async function __save(): Promise<void> {
  const data = new FormData();
  const token = await FireAuth.currentUser?.getIdToken();
  data.append("sales", JSON.stringify(allSales));
  data.append("token", token || '');
  axios
    .post(
      "https://admin.shushi-palace.am/apartments/generate_sales.php",
      data
    )
    .then((res) => Notify.success())
    .catch((err) => Notify.error());
}

const SalesService = {
  fetchAll,
  create,
  update,
  remove,
};

export default SalesService;
