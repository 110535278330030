import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "./elements/Table/Table";
import { Button } from "@mui/material";
import useUser from "../../context/useUser";
import { signOut } from "firebase/auth";
import { FireAuth } from "../../base";
import UploadModal from "./elements/UploadModal";

export default function Dashboard() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [isValid, setIsValid] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const onSignOut = () => {
    signOut(FireAuth);
    setIsValid(false);
  };

  useEffect(() => {
    if (user) return setIsValid(true);

    setIsValid(false);
    navigate("/login");
  }, [user]);

  const handleDownloadSales = () => {
    window.open("https://admin.shushi-palace.am/apartments/sales-csv.php", "_blank", 'noreferrer');
  };

  return (
    <div style={{ width: "80%", margin: "0 auto" }}>
      <header style={{ marginBottom: 20 }}>
        <h1 style={{ fontSize: 60 }}>Վաճառքներ</h1>
        <div style={{ display: "flex", gap: "11px", alignItems: "center" }}>
          <Button onClick={() => setIsUploadModalOpen(true)} variant="outlined">
            UPLOAD CSV
          </Button>

          <Button onClick={handleDownloadSales} variant="outlined">
            DOWNLOAD CSV
          </Button>

          <Button onClick={onSignOut} variant="contained" color="error" sx={{marginLeft: 'auto'}}>
            Դուրս գալ
          </Button>
        </div>
      </header>
      {isValid && <Table />}
      <UploadModal
        open={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      />
    </div>
  );
}
