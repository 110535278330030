import { onAuthStateChanged, User } from "firebase/auth";
import React, { createContext, ReactNode} from "react";
import { FireAuth } from "../base";

export const UserContext = createContext<{
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
} | null>(null);

interface Props {
  children: ReactNode;
}

export default function AuthProvider({ children }: Props) {
  const [user, setUser] = React.useState<User | null>(null);

  onAuthStateChanged(FireAuth, (authUser) => {
    if (authUser === user) return;
    setUser(authUser)
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
