import * as React from "react";
import Box from "@mui/material/Box";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { TextField, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { SaleItem, SaleType } from "../../../models/models";
import SalesService from "../../../services/Sales";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  text: "#fff",
};

interface Props {
  item: SaleItem | null;
  closeModal: () => void;
  open: boolean;
  setSales: React.Dispatch<React.SetStateAction<SaleItem[]>>;
}

export default function EditModal({ item, closeModal, open, setSales }: Props) {
  const [building, setBuilding] = React.useState(item?.building || "");
  const [floor, setFloor] = React.useState(item?.floor || "");
  const [number, setNumber] = React.useState(item?.number || "");
  const [index, setIndex] = React.useState(item?.index || "");

  const [status, setStatus] = React.useState<SaleType>(item?.status || "sold");

  const onChangeGenerator =
    (seter: React.Dispatch<React.SetStateAction<string>>) =>
    (ev: React.ChangeEvent<HTMLInputElement>) =>
      seter(ev.target.value);

  const onSave = async () => {
    const response = await SalesService.update({
      id: item?.id || "null",
      building,
      floor,
      number,
      index,
      status,
    });

    if (response) {
      setSales((prev) =>
        prev.map((sale) => {
          if (sale.id == item?.id) {
            return {
              ...sale,
              building,
              floor,
              number,
              status,
              index,
            };
          }
          return sale;
        })
      );
    }

    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  const onDelete = async () => {
    const response = await SalesService.remove(item?.id || "null");

    if (response) {
      setSales((prev) => prev.filter((sale) => sale.id !== item?.id));
    }
    closeModal();
  };

  if (!item) return null;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ marginBottom: 30 }}>
          <TextField
            style={{ width: "100%", marginBottom: 10 }}
            value={building}
            onChange={onChangeGenerator(setBuilding)}
            type="text"
            label="Մասնաշենք"
            variant="standard"
          />
          <TextField
            style={{ width: "100%", marginBottom: 10 }}
            value={floor}
            onChange={onChangeGenerator(setFloor)}
            type="text"
            label="Հարկ"
            variant="standard"
          />
          <TextField
            style={{ width: "100%", marginBottom: 10 }}
            value={number}
            onChange={onChangeGenerator(setNumber)}
            type="text"
            label="Բնկ․ համար"
            variant="standard"
          />

          <TextField
            style={{ width: "100%", marginBottom: 30 }}
            value={index}
            onChange={onChangeGenerator(setIndex)}
            label="Հերթական համարը"
            type="text"
            variant="standard"
            placeholder="ՈՉ ՊԱՐՏԱԴԻՐ"
          />

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Կարգավիճակ
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={status}
              onChange={(e, val) => setStatus(val as SaleType)}
            >
              <FormControlLabel
                value="sold"
                control={<Radio />}
                label="Վաճառված"
              />
              <FormControlLabel
                value="reserved"
                control={<Radio />}
                label="Ամրագրված"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{ display: "flex", gap: 10 }}>
          <Button onClick={onSave} variant="contained">
            Save
          </Button>
          <Button onClick={onCancel} variant="text">
            Cancel
          </Button>
          <Button onClick={onDelete} variant="outlined" color="error">
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
