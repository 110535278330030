import React, { useEffect, useState } from "react";
import { SaleItem } from "../../../../models/models";
import SalesService from "../../../../services/Sales";

export default function useSales() {
  const [sales, setSales] = useState<SaleItem[]>([]);

  useEffect(() => {
    (async () => {
      const salesResponse = await SalesService.fetchAll();

      setSales(salesResponse);
    })();
  }, []);

  return { sales, setSales };
}
