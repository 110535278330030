import { toast } from "react-toastify";

const Notify = {
  success: () =>
    toast.success("Հաջողությամբ պահպանված է", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    }),

  error: () => toast.error("Փորձեք մի փոքր ուշ", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }),
};

export default Notify;
