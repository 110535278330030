import {
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { FireAuth } from "../base";

const auth = {
  login: async (payload: { email: string; password: string }) => {
    try {
      await setPersistence(FireAuth, browserLocalPersistence);
      const resp = await signInWithEmailAndPassword(
        FireAuth,
        payload.email,
        payload.password
      );
      return resp.user;
    } catch (err) {
      return false;
    }
  },
};

const API = {
  auth,
};

export default API;
