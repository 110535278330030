import * as React from "react";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextField, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { SaleItem, SaleType } from "../../../models/models";
import { isAlreadySaleExist } from "../../../helpers/common";
import SalesService from "../../../services/Sales";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  text: "#fff",
};

interface Props {
  closeModal: () => void;
  open: boolean;
  setSales: React.Dispatch<React.SetStateAction<SaleItem[]>>;
  sales: SaleItem[];
}

export default function AddModal({ closeModal, open, setSales, sales }: Props) {
  const [building, setBuilding] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [index, setIndex] = React.useState("");
  const [status, setStatus] = React.useState<SaleType>("sold");

  const onChangeGenerator =
    (seter: React.Dispatch<React.SetStateAction<string>>) =>
    (ev: React.ChangeEvent<HTMLInputElement>) =>
      seter(ev.target.value);

  const onSave = async () => {
    const newItem: SaleItem = {
      id: "",
      building,
      floor,
      number,
      index,
      status,
    };

    const alreadyExist = isAlreadySaleExist(sales, newItem);

    if (alreadyExist)
      return alert(
        "Այս բնակարանի համար արդեն իսկ կա գրանցում, խնդրում ենք փոփոխել եղածը։"
      );

    const response = await SalesService.create({
      building,
      floor,
      number,
      index,
      status,
    });

    if (response) {
      newItem.id = response;
      setSales((prev) => [newItem, ...prev]);
    }

    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ marginBottom: 30 }}>
          <TextField
            style={{ width: "100%", marginBottom: 10 }}
            value={building}
            type="text"
            onChange={onChangeGenerator(setBuilding)}
            label="Մասնաշենք"
            variant="standard"
          />
          <TextField
            style={{ width: "100%", marginBottom: 10 }}
            value={floor}
            onChange={onChangeGenerator(setFloor)}
            label="Հարկ"
            type="text"
            variant="standard"
          />
          <TextField
            style={{ width: "100%", marginBottom: 10 }}
            value={number}
            onChange={onChangeGenerator(setNumber)}
            label="Բնկ․ համար"
            type="text"
            variant="standard"
          />
          <TextField
            style={{ width: "100%", marginBottom: 30 }}
            value={index}
            onChange={onChangeGenerator(setIndex)}
            label="Հերթական համարը(ոչ պարտադիր)"
            type="text"
            variant="standard"
            placeholder="ՈՉ ՊԱՐՏԱԴԻՐ"
          />

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Կարգավիճակ
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={status}
              name="radio-buttons-group"
              value={status}
              onChange={(e, val) => setStatus(val as SaleType)}
            >
              <FormControlLabel
                value="sold"
                control={<Radio />}
                label="Վաճառված"
              />
              <FormControlLabel
                value="reserved"
                control={<Radio />}
                label="Ամրագրված"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div style={{ display: "flex", gap: 10 }}>
          <Button onClick={onSave} variant="contained">
            Պահպանել
          </Button>
          <Button onClick={onCancel} variant="text">
            Չեղարկել
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
